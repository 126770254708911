@import "../../styles/base";
@import "perfect-scrollbar/css/perfect-scrollbar.css";

.modal {
  position: fixed;
  top: 0;
  right: 0;
  // height: calc(100% - #{$header_height});
  height: 100%;
  width: 100vw;
  padding: 0 !important;
  text-align: center;
  background: rgba(95, 146, 143, 0.7);

  @include max-media(tMax, sMax) {
    // top: 0;
    height: 100%;
  }

  &:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -4px;
  }

  &--swiper {
    top: 0;
    padding: rem(24) 0 !important;
    background: #7EA8A3;

    @include min-media('d') {
      padding: rem(48) 0 !important;
    }

    &:before {
      content: none;
    }
  }

  &__swiper-wrapper {
    overflow: hidden;
    position: relative;
  }

  &__swiper-navigation {
    width: rem(40);
    height: rem(40);
    border-radius: 50%;
    background-color: rgba(51, 114, 141, 0.50);
    position: absolute;
    top: 50%;
    transform: translateY(-23%);
    left: rem(16);
    opacity: 1;
    transition: opacity 0.3s;
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    @include min-media('d') {
      left: rem(271);
      width: rem(60);
      height: rem(60);
    }

    &--next {
      left: auto;
      right: rem(16);

      @include min-media('d') {
        right: rem(271);
      }

      .modal__swiper-icon {
        transform: rotate(180deg);
        margin: 0 0 4px 4px;
      }
    }

    &--disabled {
      opacity: 0;
      pointer-events: none;
    }

    @include min-media('xxml') {
      left: rem(410);

      &--next {
        left: auto;
        right: rem(410);
      }
    }
  }

  &__swiper-icon {
    margin-top: 4px;
    margin-right: 4px;

    svg {
      width: rem(12);
      height: rem(18);

      @include min-media('d') {
        width: auto;
        height: auto;
      }
    }
  }

  .swiper-slide {
    width: 100%;
    opacity: 0.5;
    filter: blur(5px);
    transition: opacity 0.3s, filter 0.3s;
    cursor: pointer;

    @include min-media('d') {
      width: rem(869);
    }

    img {
      width: 100%;
    }
  }

  .swiper-slide-active {
    opacity: 1;
    filter: blur(0);
  }

  &__swiper-back {
    background-color: rgba(0, 0, 0, 0.50);
    height: rem(40);
    border-radius: rem(60);
    color: $white;
    font-size: rem(16);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    position: relative;
    width: rem(375);
    margin: 0 auto rem(16) auto;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    flex-shrink: 0;
    transition: background 0.3s, color 0.3s;

    @include min-media('d') {
      height: rem(56);
      font-size: 32px;
      margin: 0 auto rem(60) auto;
    }

    &:before,
    &:after {
      content: '';
      background-color: $white;
      position: absolute;
      left: rem(10);
      transition: background 0.3s;
      width: rem(12);
      height: rem(3);

      @include min-media('d') {
        width: rem(24);
        height: rem(6);
      }
    }

    &:before {
      border-radius: 0 3px 3px 3px;
      transform: rotate(-45deg) translateY(-50%);
      top: calc(50% - #{rem(2)});

      @include min-media('d') {
        top: calc(50% - #{rem(6)});
      }
    }

    &:after {
      border-radius: 3px 0 3px 3px;
      transform: rotate(225deg) translateY(-50%);
      top: calc(50% + #{rem(3)});

      @include min-media('d') {
        top: calc(50% + #{rem(4)});
      }
    }

    &:hover,
    &:focus,
    &:active {
      background: linear-gradient(180deg, #FFE28D 0%, #FCC937 100%);
      color: $black;

      &:before,
      &:after {
        background-color: $black;
      }
    }
  }

  &__wrapper {
    width: rem(537);
    padding: rem(43) 0 rem(75) 0;
    background-color: rgba($black, 0.4);
    border-radius: rem(20);
    display: inline-block;
    text-align: left;
    vertical-align: middle;
    position: relative;
    max-height: calc(100% - 25px);

    @include max-media(dMax, mMax) {
      width: rem(440);
      padding: rem(44) 0;
    }

    @include max-media(tMax, sMax) {
      width: rem(300);
      padding: rem(32) 0;
    }

    @include max-media(xsMax, sMax) {
      padding: rem(16) 0;
    }

    &--new-redirect {
      padding: rem(32) rem(20);

      @include min-media('d') {
        padding: rem(35);
      }

      .modal__content {
        padding: 0;
        max-height: rem(240);
      }

      .modal__title {
        font-size: rem(16);
        font-style: normal;
        font-weight: 400;
        line-height: rem(25);

        @include min-media('d') {
          font-size: 50px;
          margin-bottom: rem(30);
          line-height: normal;
        }
      }

      .modal__subtitle {
        font-size: rem(13);
        font-style: normal;
        font-weight: 400;
        margin-top: rem(2);
        line-height: rem(15);
        margin-bottom: rem(10);

        @include min-media('d') {
          font-size: 26px;
          margin-top: 0;
          margin-bottom: rem(30);
          line-height: normal;
        }
      }

      .modal__btn {
        margin-top: 0 !important;
        width: 100%;

        &:before,
        &:after {
          right: rem(22);
        }
      }
    }
  }

  &__content {
    width: 100%;
    height: 100%;
    position: relative;
    padding: 0 rem(78) 0 rem(86);

    @include max-media(dMax, mMax) {
      padding: 0 rem(44) 0;
    }

    @include max-media(tMax, sMax) {
      padding: 0 rem(20) 0;
    }
  }

  &--small {
    .modal__wrapper {
      padding: rem(54) 0 rem(64);

      @include max-media(dMax, mMax) {
        padding: rem(30) 0;
      }

      @include max-media(tMax, sMax) {
        padding: rem(20) 0;
      }
    }

    .modal__content {
      padding: 0 rem(32);

      @include max-media(dMax, mMax) {
        padding: 0 rem(30);
      }

      @include max-media(tMax, sMax) {
        padding: 0 rem(20);
      }
    }
  }

  &--shortBg {
    background-image: url('/assets/images/girl-black-blur.jpg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  &--shortBgWoman {
    background-image: url('/assets/images/woman-grey-blur.jpg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  &--shortMan {
    background-image: url('/assets/images/man-grey-blur.jpg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  &.modal--medium {
    .modal__wrapper {
      width: rem(752);
      max-width: 70%;

      @include max-media(dMax, tMax) {
        width: calc(100% - #{rem(80)});
      }

      @include max-media(tMax, sMax) {
        width: calc(100% - #{rem(170)});
        max-width: rem(640);
      }
    }

    .modal__content {
      overflow: auto;
      padding: 0 rem(40);

      @include max-media(dMax, mMax) {
        padding: 0 rem(40);
      }

      @include max-media(tMax, sMax) {
        padding: 0 rem(20);
      }
    }

    .button {
      @include max-media(dMax, dMax) {
        margin-top: rem(30);
        max-width: rem(310);
      }
    }
  }

  &--large {
    & > .modal__wrapper {
      width: calc(100% - #{rem(198)});
      max-height: calc(100% - #{rem(200)});
      padding: rem(23) 0 rem(63);
      height: 100%;

      @include max-media(dMax, mMax) {
        padding: rem(40) 0 0;
        max-height: calc(100% - #{rem(62)});
        vertical-align: bottom;
        border-radius: rem(20) rem(20) 0 0;
      }

      @include max-media(tMax, sMax) {
        padding: rem(20) 0 0;
        max-height: calc(100% - #{rem(20)});
      }
    }

    & > .modal__wrapper > .modal__content {
      padding: 0;

      @include max-media(dMax, mMax) {
        padding: 0;
      }
    }

    .modal__text {
      padding: 0 rem(46) 0 rem(85);

      overflow: auto;
      max-height: 100%;
      position: relative;
      height: 100%;

      @include max-media(dMax, mMax) {
        padding: 0 rem(40) rem(40);
        margin-top: 0;
      }

      @include max-media(tMax, sMax) {
        padding: 0 rem(20) rem(20);
      }
    }
  }

  &.modal--center {
    background-color: #7ea8a3;
    background-image: url('/assets/images/pattern.png');
    z-index: 5;

    .modal__wrapper {
      text-align: center;
      padding: rem(65) 0 rem(59) 0;

      @include max-media(dMax, mMax) {
        padding: rem(44) 0;
      }
    }
  }

  &.hide {
    display: none;
  }

  &__close {
    display: block;
    transform: translate(50px, -10px);
    margin-left: auto;

    @include max-media(dMax, mMax) {
      transform: translate(0, -10px);
    }

    @include max-media(xsMax, sMax) {
      transform: translate(0, 0);
    }
  }

  &__close-icon {
    svg {
      height: 100%;
      width: 100%;
    }

    @include max-media(dMax, mMax) {
      width: rem(20);
      height: rem(20);
    }
  }

  &__icon {
    width: rem(94);
    height: rem(94);
    margin: 0 auto rem(49);

    svg {
      width: 100%;
      height: 100%;
    }

    @include max-media(dMax, mMax) {
      width: rem(48);
      height: rem(48);
      margin: 0 auto rem(10);
    }

    @include max-media(tMax, sMax) {
      width: rem(36);
      height: rem(36);
    }
  }

  &__title {
    color: $white;
    margin: 0;
    font: 700 rem(40)/rem(40) 'Calibri', sans-serif;
    font-weight: 400;
    line-height: normal;

    @include max-media(dMax, mMax) {
      font-size: rem(23);
      line-height: rem(30);
    }

    @include max-media(tMax, sMax) {
      font-size: rem(16);
      line-height: rem(25);
    }

    &--isHeading {
      font-size: rem(19);
      font-style: normal;
      font-weight: 700;
      line-height: normal;

      @include min-media('d') {
        font-size: 32px;
      }
    }
  }

  &__subtitle {
    color: $white;
    margin-top: rem(14);
    font: 500 rem(26)/rem(30) 'Calibri', sans-serif;
    font-weight: 400;
    line-height: normal;

    @include max-media(dMax, mMax) {
      font-size: rem(18);
      line-height: rem(22);
      margin-top: rem(10);
    }

    @include max-media(tMax, sMax) {
      font-size: rem(13);
      line-height: rem(15);
      margin-top: rem(2);
    }
  }

  &__text {
    color: $white;
    margin-top: rem(40);
    font: 500 rem(25)/rem(30) 'Calibri', sans-serif;

    @include max-media(dMax, mMax) {
      font-size: rem(18);
      line-height: rem(22);
      margin-top: rem(20);
    }

    @include max-media(tMax, sMax) {
      font-size: rem(13);
      line-height: rem(15);
    }

    h2 {
      font: 700 rem(32)/rem(35) "Calibri", sans-serif;

      @include max-media(dMax, mMax) {
        font-size: rem(23);
        line-height: rem(35);
      }

      @include max-media(tMax, sMax) {
        font-size: rem(17);
        line-height: rem(25);
      }
    }

    ul {
      padding-left: rem(25);
      margin: 0;

      li {
        font: inherit;

        &:not(:first-child) {
          margin-top: rem(20);

          @include max-media(dMax, mMax) {
            margin-top: rem(15);
          }
        }
      }
    }

    em {
      font-weight: 700;
      padding-left: rem(38);
      position: relative;
      display: inline-block;
      margin-top: rem(22);
      font-style: normal;

      @include max-media(tMax, sMax) {
        padding-left: rem(25);
      }

      &:before {
        content: '';
        height: 100%;
        width: rem(2);
        background-color: $white;
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    ol {
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.8s ease;

      a {
        text-decoration: underline;
        color: cadetblue;
      }
    }

    p + p {
      margin-top: rem(22);
    }

    &--new {
      sup {
        font-size: rem(9);

        @include min-media('d') {
          font-size: rem(15);
        }
      }

      p {
        font-weight: 400;
        line-height: normal;

        bold {
          font-size: 13px;
          font-weight: 400;
          line-height: 13px;

          @include min-media('d') {
            font-size: 25px;
            line-height: 25px;
            font-weight: 700;
          }
        }
      }

      p + p {
        margin-top: rem(9);
      }

      h3 {
        font-size: rem(19);
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: rem(16);
        margin-bottom: rem(16);

        @include min-media('d') {
          font-size: 32px;
          margin-top: rem(40);
          margin-bottom: rem(9);
        }
      }

      h4 {
        font-size: rem(19);
        font-style: normal;
        font-weight: 700;
        line-height: rem(25);
        margin-top: rem(16);
        margin-bottom: rem(16);

        @include min-media('d') {
          font-size: rem(25);
          margin-top: rem(40);
          margin-bottom: rem(9);
        }
      }

      .light-title {
        font-size: 32px;
        font-weight: 400;
        line-height: 39.06px;
        margin: 40px 0 9px;
      }

      .small-title {
        margin: 9px 0;
      }

      h5 {
        font-size: 25px;
        font-weight: 700;
        line-height: 25px;
        margin: 40px 0 0;
      }

      ul {
        li {
          font-weight: 400;
          line-height: normal;
          font-size: rem(14);

          @include min-media('d') {
            font-size: rem(24);
          }
        }
      }

      ol {
        a {
          color: #FFFFFF;
        }

        li {
          margin-bottom: rem(10);
          font-weight: 400;
          line-height: normal;
          font-size: rem(14);

          @include min-media('d') {
            font-size: rem(24);
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  &__accordeon {
    color: $white;
    font: 700 rem(23)/rem(35) "Calibri", sans-serif;
    position: relative;
    display: flex;
    align-items: center;
    margin-top: rem(22);

    &:before {
      content: '';
      width: rem(14);
      height: rem(14);
      border: 1px solid $white;
      border-right: none;
      border-bottom: none;
      display: block;
      margin-right: rem(14);
      transform: rotate(-135deg) translateY(5px);
      transition: transform 0.6s ease;

      @include max-media(mMax, mMax) {
        width: rem(8);
        height: rem(8);
        transform: translateX(3px) rotate(-135deg) translateY(2px);
      }
    }

    &.active:before {
      transform: translateX(7px) rotate(45deg) translateY(5px);

      @include max-media(mMax, mMax) {
        transform: translateX(5px) rotate(45deg) translateY(3px);
      }
    }

    @include max-media(dMax, mMax) {
      font-size: rem(20);
      line-height: rem(32);
    }

    @include max-media(tMax, sMax) {
      font-size: rem(15);
      line-height: rem(20);
    }
  }

  &__buttons {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    // margin-top: rem(150);
    margin-top: rem(50);

    @include max-media(mMax, mMax) {
      margin-top: rem(50);
    }

    & > * {
      width: auto;
      flex-shrink: 0;

      &:not(:first-child) {
        margin-left: rem(24);
      }
    }
  }

  &__navigation {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 25px;
    width: 100%;
    left: 0;
    display: flex;
    justify-content: space-between;
    pointer-events: none;
  }

  &__navigation-arrow {
    height: rem(20);
    width: rem(20);
    margin: 0 rem(30);
    cursor: pointer;
    pointer-events: all;

    &:before,
    &:after {
      content: '';
      width: rem(20);
      height: rem(5);
      background-color: #FFE28D;
      position: absolute;
    }

    &:before {
      border-radius: 3px 0px 3px 3px;
      transform: rotate(45deg) translateY(-50%);
      top: calc(50% - #{rem(4)});
    }

    &:after {
      border-radius: 0px 3px 3px 3px;
      transform: rotate(135deg) translateY(-50%);
      top: calc(50% + #{rem(4)})
    }

    &:first-child {
      transform: rotate(180deg);
    }
  }

  &__pagination {
    position: absolute;
    height: 15px;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;

    span {
      border-radius: 50%;
      background-color: rgba($black, 0.4);
      width: rem(18);
      height: rem(18);
      display: block;

      &.active {
        background-image: linear-gradient(to right, #FFE28D, #FCC937);
      }

      & + span {
        margin-left: rem(10);
      }
    }

    @include max-media(dMax, mMax) {
      display: none;
    }
  }

  .button {
    margin-top: rem(21);

    @include max-media(dMax, mMax) {
      margin-top: rem(10);
    }

    @media screen and (max-width: 735px) and (orientation: landscape) {
      font-size: 12px;
      border-radius: 7px;
      padding: 0 15px;
    }

    &[disabled] {
      display: none;
    }
  }
}
