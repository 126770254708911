// Breakpoints
$min-media-sizes: (
	"m": 375px,
	"t": 768px,
	'md': 1024px,
	"d": 1280px,
	"xl": 1440px,
	"xxl": 1600px,
	"xxml": 1800px,
	"xxxl": 1920px
);

$max-media-sizes: (
	"sMax": 374px,
	"xsMax": 480px,
	"mMax": 767px,
	"tMax": 1023px,
	"dMax": 1279px,
	"xlMax": 1439px
);

$max-height-sizes: (
  "sMax": 374px,
	"mMax": 767px,
	"tMax": 1023px,
	"dMax": 1079px
);

// Colors
$white: #fff;
$razzmatazz: #e50058;
$black: #070707 !default;
$black-1: #000000;
$nero: #202020;
$dim-gray: #6a6a6a;
$white: #ffffff;
$ghost-white: #f4f4f9;
$gainsboro: #e0e0e0;
$whisper: #e5e5e5;
$silver: #bdbdbd;
$gulf-stream: #7ea8a3;
$gum-leaf: #a9b6ac;
$watercourse: #006746;
$dark-pastel-green : #0EA839;
$fire-brick: #A80E0E;

// Values
$header_height: rem(80);
$aside_width: rem(425);
