@import "../../styles/base";

.button {
    background: linear-gradient(180deg, #FFE28D 0%, #FCC937 100%);
    border-radius: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: rem(71);
    width: 100%;
    text-decoration: none;
    padding: rem(14) rem(22);

    color: $black;
    font: 700 rem(25)/rem(25) 'Calibri', sans-serif;

    @include max-media(dMax, mMax) {
        height: rem(40);
        padding: rem(11) rem(22);
        border-radius: rem(7);
        font-size: rem(17);
        line-height: rem(25);
    }

    &--orange {
        background: linear-gradient(270deg, rgba(254, 212, 91, 0.6) -5.21%, rgba(253, 182, 45, 0.6) -5.21%, rgba(253, 158, 46, 0.6) 72.29%),
                    linear-gradient(180deg, #FFE28D 0%, #FCC937 100%);
        border-radius: rem(14);
        height: rem(54);
        color: rgba($black, 0.7);
        font: 700 rem(19)/rem(25) 'Calibri', sans-serif;

        @include max-media(tMax, sMax) {
            height: rem(40);
            font-size: rem(17);
            border-radius: rem(7);
        }
    }

    &--arrow {
        background: linear-gradient(180deg, #FFE28D 0%, #FCC937 100%);
        border-radius: rem(14);
        font: 700 rem(25)/rem(25) 'Calibri', sans-serif;
        position: relative;
        justify-content: flex-start;
        width: rem(365);

        @include max-media(dMax, mMax) {
            width: 100%;
            height: rem(40);
            padding: rem(11) rem(22);
            border-radius: rem(7);
            font-size: rem(17);
            line-height: rem(25);
        }

        &:before,
        &:after {
            content: '';
            width: rem(20);
            height: rem(5);
            background-color: $black;
            position: absolute;
            right: rem(59);

            @include max-media(dMax, mMax) {
                width: rem(13);
                height: rem(3);
                right: rem(20);
            }
        }

        &:before {
            border-radius: 3px 0px 3px 3px;
            transform: rotate(45deg) translateY(-50%);
            top: calc(50% - #{rem(4)});

            @include max-media(dMax, mMax) {
                top: calc(50% - #{rem(4)});
            }
        }

        &:after {
            border-radius: 0px 3px 3px 3px;
            transform: rotate(135deg) translateY(-50%);
            top: calc(50% + #{rem(4)});

            @include max-media(dMax, mMax) {
                top: calc(50% + #{rem(2)});
            }
        }
    }

    &:disabled {
        filter: grayscale(1);
        opacity: 0.5;
        pointer-events: none;
    }
}
