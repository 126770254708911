.heading {
	&-1 {
		font: 700 rem(28)/rem(35) 'Calibri', sans-serif;

		@include min-media('t') {
			font-size: rem(48);
			line-height: rem(59);
		}

		@include min-media('d') {
			font-size: rem(54);
			line-height: rem(67);
		}
	}

	&-2 {
		font: 700 rem(20)/rem(27) 'Calibri', sans-serif;

		@include min-media('t') {
			font-size: rem(32);
			line-height: rem(44);
		}

		@include min-media('d') {
			font-size: rem(34);
			line-height: rem(46);
		}
	}

	&-3 {
		font: 700 rem(20)/rem(27) 'Calibri', sans-serif;

		@include min-media('d') {
			font-size: rem(24);
			line-height: rem(30);
		}
	}
}

.text {
	&-1 {
		font: 500 rem(14)/rem(22) 'Calibri', sans-serif;

		@include min-media('d') {
			font-size: rem(16);
			line-height: rem(25);
		}
	}

	&-2 {
		font: 500 rem(16)/rem(24) 'Calibri', sans-serif;
	}
}
