@import "../styles/base";

main {
    background-color: #7ea8a3;
    background-image: url('/assets/images/pattern.png');
    background-repeat: no-repeat;
    background-size: cover;
    //min-height: calc(100vh - $header_height);
    min-height: 100vh;

    @include max-media(tMax, sMax) {
        min-height: calc(100vh);
        min-height: calc(var(--vh, 1vh) * 100);
    }
}
