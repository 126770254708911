@import "../base";

.statistic {
    overflow: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;

    min-height: calc(100vh - #{$header_height});
    background-color: $gulf-stream;

    @include max-media(tMax, sMax) {
        min-height: calc(100vh); // todo fix
        min-height: calc(var(--vh, 1vh) * 100);
    }

    &__header {
        background-color: rgba(95, 146, 143, 0.7);
        padding: rem(20) rem(20) rem(21) rem(22);
        display: flex;
        align-items: center;
        justify-content: flex-start;

        @include max-media(tMax, sMax) {
            padding: rem(20) rem(20) rem(12) rem(111);
        }

        @include max-media(mMax, sMax) {
            padding: rem(20) rem(20) rem(12) rem(65);
        }

        @media screen and (max-width: 735px) and (orientation: landscape) {
            padding-left: rem(24);
        }
    }

    &__title {
        font: 700 rem(50) / rem(60) 'Calibri', sans-serif;
        color: $white;

        @include max-media(dMax, mMax) {
            font-size: rem(36);
            line-height: rem(43);
        }

        @include max-media(tMax, sMax) {
            font-size: rem(25);
            line-height: rem(26);
        }

        @media screen and (max-width: 735px) and (orientation: landscape) {
            font-size: rem(18);
            line-height: rem(20);
            margin-right: rem(5);
        }
    }

    &__buttons {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-left: auto;

        & > * {
            width: auto;

            &:not(:first-child) {
                margin-left: rem(19);

                @include max-media(tMax, sMax) {
                    margin-left: rem(10);
                }

                @media screen and (max-width: 735px) and (orientation: landscape) {
                    margin-left: rem(5);
                }
            }

            @include max-media(mMax, sMax) {
                padding: 0 rem(5);
            }

            @media screen and (max-width: 735px) and (orientation: landscape) {
                font-size: rem(14);
                line-height: rem(20);
            }
        }
    }

    &__content {
        margin: 0 rem(-10);
        overflow: hidden;
        padding: rem(31) rem(20) rem(21) rem(22);
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;

        @include max-media(mMax, sMax) {
            margin: 0;
        }

        & > * {
            width: 100%;
            max-width: rem(1280);
            margin: rem(10) auto;

            @include max-media(tMax, sMax) {
                margin: 0 0 rem(10);
                width: 100%;
            }
        }
    }
}
