@import "../../styles/base";

.form {
    margin-top: rem(48);

    @include max-media(dMax, mMax) {
        margin-top: rem(29);
    }

    @include max-media(tMax, sMax) {
        margin-top: rem(24);
    }

    & > *:not(:first-child) {
        margin-top: rem(19);

        @include max-media(dMax, mMax) {
            margin-top: rem(10);
        }
    }
}
