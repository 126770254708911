@import "../../styles/base";

$parent: "card";

.#{$parent} {
    border-radius: rem(20);
    background-color: $white;

    &__header {
        background-color: rgba($gulf-stream, 0.6);
        color: $white;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: rem(12) rem(11) rem(12) rem(17);
    }

    &__title {
        font: 500 rem(32) / rem(38) 'Calibri', sans-serif;
        max-width: 70%;
        cursor: pointer;

        @include max-media(dMax, mMax) {
            font-size: rem(23);
            line-height: rem(35);
            font-weight: 700;
        }

        @include max-media(tMax, sMax) {
            font-size: rem(20);
        }
    }

    &__arrow {
        display: flex;
        align-items: center;
        margin-left: rem(20);
        transform: rotate(0);
        transition: transform 0.3s;

        svg {
            position: relative;
            bottom: -3px;
        }
    }

    &__select {
        margin-left: auto;
    }

    &__content-wrapper {
        display: grid;
        padding: rem(0) rem(25) rem(0) rem(17);
        grid-template-rows: 0fr;
        transition: grid-template-rows 0.5s, padding 0.5s;
    }

    &__content {
        overflow: hidden;
        padding-top: 0;
    }

    &__row {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        position: relative;

        & + .card__row {
            margin-top: rem(25);

            @include max-media(dMax, mMax) {
                margin-top: rem(25);
            }
        }
    }

    &__row-name {
        font: 500 rem(19) / rem(23) 'Calibri', sans-serif;
        color: $black;
        width: 25%;

        @include max-media(dMax, sMax) {
            font-size: rem(16);
            line-height: rem(20);
            width: fit-content;
        }
    }

    &--range {
        .card__row-range {
            display: block;
        }

        .card__row-value {
            width: auto;
            margin-left: rem(20);

            @include max-media(dMax, mMax) {
                top: rem(-23);
            }
        }

        .card__row-name {
            @include max-media(dMax, mMax) {
                position: absolute;
                top: rem(-25);
                width: auto;
            }

            @include max-media(tMax, sMax) {
                top: rem(-23);
            }
        }

        .card__row + .card__row {
            @include max-media(dMax, mMax) {
                margin-top: rem(44);
            }
        }
    }

    &__row-range {
        display: none;
        background: rgba($black, 0.2);
        border-radius: rem(20);
        width: 100%;
        max-width: rem(511);
        display: block;
        margin-left: rem(50);
        height: rem(8);
        position: relative;

        span {
            position: absolute;
            width: 100%;
            max-width: rem(150);
            height: rem(8);
            border-radius: rem(20);
            background: $dark-pastel-green;
        }

        @include max-media(dMax, mMax) {
            max-width: 100%;
        }
    }

    &__row--incorrect .card__row-range span {
        background: $fire-brick;
        right: 0;
    }

    &__row--disabled {
        opacity: 0.5;
    }

    &__row-value {
        color: $watercourse;
        margin-left: rem(50);
        width: 50%;

        @include max-media(dMax, mMax) {
            width: auto;
        }
    }

    &__select {
        position: relative;
    }

    &__select-option {
        border: 1px solid $white;
        border-radius: rem(14);
        display: flex;
        align-items: center;
        color: $white;
        min-height: rem(40);
        padding: rem(7);
        min-width: rem(169);
        justify-content: center;
        font: 500 rem(19) / rem(23) 'Calibri', sans-serif;

        @include max-media(tMax, sMax) {
            font-size: rem(14);
            line-height: rem(17);
            border-radius: rem(7);
        }

        @media screen and (max-width: 735px) and (orientation: landscape) {
            min-width: rem(100);
        }

        &:after {
            content: '';
            margin-left: rem(10);
            width: rem(5);
            height: rem(5);
            background-image: linear-gradient(45deg, transparent 50%, $white 50%);
            transform: rotate(135deg);
            transition: transform 0.3s ease;
        }

        &--open:after {
            transform: rotate(315deg);
        }
    }

    &__options {
        display: block;
        position: absolute;
        background-color: $gulf-stream;
        margin-top: rem(15);
        padding: rem(10) rem(20);
        z-index: 1;
        right: 0;
        min-width: rem(169);
        font: 500 rem(19) / rem(23) 'Calibri', sans-serif;
        border: 1px solid $white;
        border-radius: rem(14);

        @include max-media(tMax, sMax) {
            font-size: rem(14);
            line-height: rem(17);
            border-radius: rem(7);
        }
    }

    &__option {
        display: block;
        cursor: pointer;

        & + .card__option {
            margin-top: rem(15);
        }

        input {
            display: none;
        }
    }

    &__diagramma {
        width: 100%;
        position: relative;
        margin: 0 auto;
        pointer-events: none;
    }

    &__unit {
        stroke-width: 25;
        stroke-dasharray: 76;
        transform-origin: center;
    }

    &--opened {
        .#{$parent} {
            &__content-wrapper {
                grid-template-rows: 1fr;
                padding: rem(16) rem(25) rem(32) rem(17);
            }

            &__content {
                overflow: hidden;
                padding-top: rem(20);
            }

            &__arrow {
                transform: rotate(180deg);
            }
        }
    }
}
