@font-face {
  font-family: 'Calibri';
  src: url('../assets/fonts/Calibri-Bold.woff2') format('woff2'),
  url('../assets/fonts/Calibri-Bold.ttf') format('truetype'),
  url('../assets/fonts/Calibri-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
    font-family: 'Calibri';
    src: url('../assets/fonts/Calibri-Regular.woff2') format('woff2'),
    url('../assets/fonts/Calibri-Regular.ttf') format('truetype'),
    url('../assets/fonts/Calibri-Regular.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
