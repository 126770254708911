@import '../../styles/base';

.aside {
  width: $aside_width;
  background-color: rgba(95, 146, 143, 0.7);
  height: 100%;
  margin-left: auto;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  opacity: 1;
  transition: opacity 0.8s, transform 0.8s;

  @include max-media(dMax, mMax) {
    width: rem(295);
  }

  @include max-media(tMax, sMax) {
    overflow: auto;
  }

  @media screen and (max-width: 735px) and (orientation: landscape) {
    width: 48%;
  }

  &--hidden {
    opacity: 0;
    transform: translateX(100%);
  }

  &__header {
    margin: rem(48) auto 0;
    height: rem(56);
    border-radius: rem(60);
    color: $white;
    font: 500 rem(32)/rem(38) 'Calibri', sans-serif;
    position: relative;
    width: calc(100% - #{rem(40)});
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
    flex-shrink: 0;
    transition: all 0.3s ease;

    &:not(.aside__header--no-cl) {
      background-image: linear-gradient(to bottom, #FFE28D, #FCC937);
      color: $black;

      &:before,
      &:after {
        background-color: $black;
      }
    }

    @include max-media(dMax, dMax) {
      font-size: rem(16);
      line-height: rem(20);
      height: rem(38);
      margin: rem(20) auto 0;
      width: calc(100% - #{rem(40)});
    }

    &:before,
    &:after {
      content: '';
      width: rem(20);
      height: rem(5);
      background-color: $white;
      position: absolute;
      left: rem(10);

      @include max-media(dMax, dMax) {
        width: rem(15);
        height: rem(3);
      }
    }

    &:before {
      border-radius: 0 3px 3px 3px;
      transform: rotate(-45deg) translateY(-50%);
      top: calc(50% - #{rem(4)});
    }

    &:after {
      border-radius: 3px 0px 3px 3px;
      transform: rotate(225deg) translateY(-50%);
      top: calc(50% + #{rem(4)});

      @include max-media(dMax, dMax) {
        top: calc(50% + #{rem(3)});
      }
    }

    &:hover,
    &:focus,
    &:active {
      background-image: linear-gradient(to bottom, #FFE28D, #FCC937);
      color: $black;

      &:before,
      &:after {
        background-color: $black;
        // transition: background-color 0.3s ease;
      }
    }

    & + .aside__wrapper {
      margin-top: auto;

      @include max-media(tMax, sMax) {
        margin: auto;
      }
    }

    &--large {
      font-size: rem(30);
      font-weight: 400;
      height: rem(59);
      margin-top: rem(48);
      padding: rem(30);
      text-align: center;

      @include min-media('xxl') {
        font-size: rem(32);
        padding: rem(10) rem(15) rem(10) rem(40);
      }

      &:before {
        top: calc(40% - 0.25rem);
        transform: rotate(-45deg) translateY(1%);
      }

      &:after {
        top: calc(56% + #{rem(4)});
        transform: rotate(225deg) translateY(10%);
      }

      &:before,
      &:after {
        width: rem(26);
        height: rem(3);
        @include min-media('d') {
          width: rem(26);
          height: rem(4);
        }
        @include min-media('xxl') {
          width: rem(26);
          height: rem(5);
        }
      }
    }

    &--test-content {
      width: rem(375);
      margin: 0 auto rem(21) auto;
      background: rgba(0, 0, 0, 0.50);
    }
  }

  &__btn-prev {
    width: 100%;
    opacity: 0;
    margin: rem(30) rem(20) 0;
    transition: opacity 0.3s;
    margin: rem(30) auto 0;
    pointer-events: none;

    &--visible {
      pointer-events: auto;
      opacity: 1;
    }
  }

  &__title {
    margin-top: rem(19);
    color: $white;
    margin-bottom: rem(35);
    font: 500 rem(32)/rem(38) 'Calibri', sans-serif;
    padding: 0 rem(44) 0 rem(30);

    @include max-media(dMax, dMax) {
      font-size: rem(23);
      line-height: rem(28);
    }

    @include max-media(dMax, mMax) {
      padding: 0 rem(20);
    }

    @include max-media(tMax, sMax) {
      font-size: rem(16);
      line-height: rem(20);
    }

    &--big {
      margin-top: rem(28);
      margin-bottom: rem(30);
      line-height: normal;
      font-weight: 400;

      @include min-media('d') {
        margin-top: rem(48);
        margin-bottom: rem(50);
        font-size: rem(32);
      }
    }

    &--history {
      background-image: linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
      border-radius: 60px;
      width: 100%;
      text-align: center;
      padding: 10px;
      margin: 48px auto 50px auto;
      max-width: 375px;
    }
  }

  &__subtitle {
    color: $white;
    font: 700 rem(26)/rem(32) 'Calibri', sans-serif;
    padding: 0 rem(44) 0 rem(30);
    margin-top: rem(30);

    @include max-media(dMax, dMax) {
      margin-top: rem(45);
      font-size: rem(19);
      line-height: rem(23);
    }

    @include max-media(dMax, mMax) {
      padding: 0 rem(20);
      margin-top: rem(20);
    }

    @include max-media(tMax, sMax) {
      font-size: rem(14);
      line-height: rem(16);
    }

    span {
      font: 700 rem(20)/rem(24) 'Calibri', sans-serif;
      display: block;
      margin-top: rem(10);

      @include max-media(dMax, dMax) {
        font-size: rem(15);
        line-height: rem(17);
      }

      @include max-media(tMax, sMax) {
        font-size: rem(10);
        line-height: rem(12);
        margin-top: rem(3);
      }
    }

    &--big {
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      //margin-bottom: rem(30);

      @include min-media('d') {
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
      }
    }
  }

  &__text {
    margin-bottom: rem(17);
    color: $white;
    font: 500 rem(26)/rem(32) 'Calibri', sans-serif;
    padding: 0 rem(44) 0 rem(30);
    margin-top: rem(36);
    width: 100%;

    @include max-media(dMax, dMax) {
      font-size: rem(19);
      line-height: rem(23);
    }

    @include max-media(dMax, mMax) {
      padding: 0 rem(20);
      margin-top: rem(27);
    }

    @include max-media(tMax, sMax) {
      font-size: rem(14);
      line-height: rem(16);
    }

    & + .aside__wrapper {
      @include max-media(tMax, sMax) {
        max-height: none;
      }
    }

    &--big {
      font-size: 19px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: rem(30);
      margin-bottom: 0;
    }
  }

  &__timing {
    position: fixed;
    top: calc(#{$header_height} + #{rem(10)});
    left: calc(50% - #{$aside_width});
    background-color: $white;
    border-radius: rem(14);
    padding: rem(20);
    font: 400 rem(26) / rem(32) 'Calibri', sans-serif;
    color: #33728D;
    max-width: rem(346);
    display: flex;
    align-items: flex-start; // добавить адаптивы

    @include max-media(dMax, sMax) {
      font-size: rem(14);
      line-height: rem(18);
      padding: rem(10);
      width: rem(350);
      left: calc(50% - #{rem(295)});
    }

    @include max-media(tMax, sMax) {
      top: rem(25);
    }

    @media (max-width: 811px) {
      width: 43%;
    }

    @include max-media(sMax, sMax) {
      display: none;
    }
  }

  &__timing-icon {
    margin-right: rem(25);
    width: rem(69);
    height: rem(69);
    flex-shrink: 0;

    svg {
      width: 100%;
      height: 100%;
    }

    @include max-media(dMax, tMax) {
      width: rem(69);
      height: rem(69);
      margin-right: rem(20);
    }
  }

  &__footer {
    margin-top: auto;
    width: 100%;
    background: rgba(0, 0, 0, 0.4);
    padding: rem(24) rem(30) rem(46);

    @include max-media(dMax, dMax) {
      padding: rem(20) rem(30);
    }

    @include max-media(dMax, mMax) {
      padding: rem(20) rem(14);
    }
  }

  &__footer-title {
    color: $white;
    margin-bottom: rem(27);
    font: 500 rem(32)/rem(38) 'Calibri', sans-serif;

    @include max-media(dMax, dMax) {
      font-size: rem(19);
      line-height: rem(23);
      margin-bottom: rem(20);
    }

    @include max-media(tMax, sMax) {
      font-size: rem(16);
      line-height: rem(20);
    }
  }

  &__wrapper {
    background-color: rgba(225, 233, 233, 0.4);
    border-radius: rem(20);
    margin: 0 rem(30);
    padding: rem(20) 0 rem(20) rem(20);
    width: calc(100% - #{rem(60)});
    // max-height: calc(100% - 310px);
    position: relative;
    min-height: rem(80);

    .aside__options {
      padding-right: rem(20);
    }

    @include max-media(dMax, mMax) {
      // max-height: calc(100% - 242px);
      padding: rem(10) 0 rem(10) rem(10);
      border-radius: rem(14);
      margin: 0 rem(20);
      width: calc(100% - #{rem(40)});
    }

    @media screen and (max-width: 735px) and (orientation: landscape) {
      max-height: rem(200);
    }

    &--noBg {
      @include max-media(tMax, sMax) {
        max-height: none;
      }
    }

    .ps__rail-y {
      right: 9px !important;
    }

    .ps__thumb-y {
      background-color: #33728D;
      background-image: none;
      border-radius: rem(10);
    }

    & + .aside__footer {
      @include max-media(tMax, sMax) {
        position: fixed;
        bottom: 0;
        right: rem(295);
        width: calc(100% - #{rem(295)});
        background: linear-gradient(to top, rgba(#335D5B, 0.7) 0%, rgba(109, 135, 134, 0) 58%);
        padding-top: 0;
      }

      @media screen and (max-width: 735px) and (orientation: landscape) {
        width: 52%;
        right: 48%;
        padding-bottom: rem(20);
      }

      .aside__footer-title {
        @include max-media(tMax, sMax) {
          background: rgba(109, 135, 134, 0.6);
          border-radius: rem(7);
          position: absolute;
          margin: 0;
          bottom: rem(76);
          left: rem(58);
          width: rem(213);
          padding: rem(10);
          visibility: hidden;
          opacity: 0;
          transition: opacity 0.3s ease, visibility 0s ease;
        }
      }

      &.aside__footer--tooltip {
        .aside__footer-title {
          @include max-media(tMax, sMax) {
            visibility: visible;
            opacity: 1;
          }
        }
      }

      .button {
        @include max-media(tMax, sMax) {
          width: 60%;
          margin: auto;
        }

        @media screen and (max-width: 735px) and (orientation: landscape) {
          width: 100%;
        }
      }
    }

    &--new {
      margin-top: rem(20) !important;
      margin-bottom: rem(20) !important;

      @include min-media('d') {
        margin-top: rem(50) !important;
        margin-bottom: rem(20) !important;
      }
    }

    &--no-bg {
      background-color: transparent;
      padding: 0;

      .aside__options {
        padding-right: 0;
      }
    }
  }

  &__options {
    //padding-right: rem(20);
    height: 100%;
    overflow: auto;
    position: relative;
    padding-right: 0;

    @include max-media(dMax, mMax) {
      padding-right: rem(10);
    }

    &.ps--active-y {
      padding-right: rem(20);

      @include max-media(dMax, mMax) {
        padding-right: rem(20);
      }
    }

    &--scroll {
      padding-right: rem(20);
    }
  }

  &__option-arrowLeft {
    display: none;
  }

  &__option-arrowView {
    display: block;
    margin-left: rem(10);
  }

  &__icon-lock {
    height: rem(24);
  }

  &__option {
    background-color: #F2F5F6;
    min-height: rem(60);
    padding: rem(18) rem(20) rem(18) rem(15);
    color: #3D8873;
    border-radius: rem(14);
    font: 500 rem(19)/rem(19) 'Calibri', sans-serif;
    font-weight: 400;
    line-height: normal;
    display: flex;
    align-items: center;
    cursor: pointer;
    opacity: 0.9;
    transition: color 0.3s, background-color 0.3s, opacity 0.3s;

    &--white {
      display: flex;
      justify-content: space-between;
      align-items: center;
      opacity: 1;
      background-color: #FFFFFF;
    }

    &--new-mark {
      font-weight: 700;
      color: #006746;
      justify-content: normal;
      opacity: 1 !important;

      .aside__option-arrowLeft {
        display: block;
        margin-right: rem(10);
      }

      .aside__option-arrowView {
        display: none;
      }
    }

    &--lock {
      display: flex;
      justify-content: space-between;
      align-items: center;
      pointer-events: none;
    }

    &--viewed {
      display: flex;
      justify-content: space-between;
      align-items: center;
      opacity: 0.6;
    }

    &:hover,
    &:focus,
    &:active,
    &.aside__option--mark {
      color: $watercourse;
      background-color: $white;
    }

    @include max-media(dMax, mMax) {
      min-height: rem(39);
      border-radius: rem(10);
      padding: rem(11) rem(20) rem(11) rem(15);
      font-size: rem(14);
      line-height: rem(17);
    }

    & + .aside__option {
      margin-top: rem(15);

      @include max-media(dMax, mMax) {
        margin-top: rem(10);
      }
    }

    &.aside__option--opacity {
      opacity: 0.5;
      color: $watercourse;
      background-color: $white;
    }

    &[data-disabled="true"] {
      filter: blur(2px);
      pointer-events: none;
      color: $watercourse;
      background-color: $white;
    }

    &[data-dirty="true"] {
      opacity: 0.6;
    }

    span {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: #006746;

      &:before {
        content: '';
        width: rem(30);
        height: rem(30);
        border: 1px solid #9FC3B9;
        border-radius: 50%;
        margin-right: rem(10);
        flex-shrink: 0;

        @include max-media(dMax, mMax) {
          width: rem(28);
          height: rem(28);
        }
      }
    }

    input {
      display: none;

      &:checked + span {
        font-weight: 700;

        &:before {
          border: 1px solid #006746;
          background-image: url('/assets/images/done.svg');
          background-repeat: no-repeat;
          background-size: cover;
        }
      }
    }
  }

  &__card-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: rem(17);
    margin-bottom: rem(20);

    @include min-media('xl') {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }

  &__card {
    display: flex;
    flex-direction: column;
    padding: rem(22) rem(20) rem(12) rem(15);
    background-color: #FFF;
    border-radius: rem(14);
    height: rem(237);
    cursor: pointer;

    span {
      margin-top: auto;
      font-size: 17px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      color: #006746;
      margin-top: rem(21);

      @include min-media('d') {
        font-size: 19px;
        margin-top: rem(71);
      }
    }

    input {
      display: none;

      &:checked + .aside__card-value {
        background-color: #006746;

        &:before {
          content: '';
          width: rem(19);
          height: rem(13);
          background-image: url('/assets/images/done-white.svg');
          background-repeat: no-repeat;
          background-size: cover;
        }
      }
    }
  }

  &__card-value {
    margin-left: auto;
    width: rem(30);
    min-height: rem(30);
    border-radius: 50%;
    background-color: rgba(159, 195, 185, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s;
  }

  &__info {
    position: fixed;
    top: calc(#{$header_height} - #{rem(20)});
    left: rem(30);
    width: 100%;
    max-width: calc(100% - #{$aside_width} - #{rem(76)});
    background-color: rgba(255, 255, 255, 0.85);
    color: #474747;
    border-radius: rem(16);
    padding: rem(20);
    font: 500 rem(26) / rem(29) 'Calibri', sans-serif;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.25);
    max-height: 375px;
    transition: max-height 0.3s ease;

    & > * {
      transition: all 0.3s ease;
    }

    @include max-media(dMax, dMax) {
      font-size: rem(18);
      line-height: rem(21);
    }

    @include max-media(dMax, mMax) {
      max-width: calc(100% - #{rem(295)} - #{rem(70)});
    }

    @include max-media(tMax, sMax) {
      left: rem(82);
      max-width: calc(100% - #{rem(295)} - #{rem(102)});
      font-size: rem(13);
      line-height: rem(15);
      top: rem(-10);
      padding: rem(20) rem(25) rem(10) rem(10);
    }

    @media screen and (max-width: 735px) and (orientation: landscape) {
      max-width: 52%;
      left: 0;
    }

    &.aside__info--hide {
      max-height: rem(20);
      overflow: hidden;

      & > * {
        opacity: 0;
        visibility: hidden;
      }
    }

    &--test-content {
      position: static;
      margin-bottom: rem(50);
      max-width: 100%;
      font-size: rem(19);
      padding: rem(30) rem(20);
      font-weight: 400;
      align-items: flex-start;
      line-height: normal;
      background-color: rgba(255, 255, 255, 0.60);
    }
  }

  &__info-icon {
    width: rem(36);
    height: rem(36);
    margin-right: rem(10);
    flex-shrink: 0;

    * {
      width: 100%;
      height: 100%;
    }

    @include max-media(tMax, sMax) {
      width: rem(18);
      height: rem(18);
    }
  }

  &__info-close {
    width: rem(15);
    height: rem(15);
    flex-shrink: 0;
    position: absolute;
    right: rem(8);
    top: rem(21);

    path {
      fill: rgba(#474747, 0.4);
    }

    * {
      width: 100%;
      height: 100%;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  &__tests-content {
    width: 100%;
    height: 100%;
    overflow: auto;
    padding: rem(48) rem(38);
    display: flex;
    flex-direction: column;
  }

  &--test {
    width: 100%;
    background-color: transparent;
    transition: opacity 0s;
  }

  &--hidden-options {
    opacity: 0;
  }

  &__btn-test {
    margin: auto auto 0 auto;
    min-height: rem(40);
    opacity: 1;
    transition: opacity 0.3s;

    @include min-media('d') {
      min-height: rem(71);
    }

    &--hidden {
      opacity: 0;
      pointer-events: none;
    }
  }

  &__btn-next {
    opacity: 1;
    transition: opacity 0.3s;

    &--hidden {
      opacity: 0;
      pointer-events: none;
    }
  }

  &__btn-conteiner {
    margin: auto rem(30) rem(16);

    @include max-media('dMax', 'mMax') {
      margin: auto rem(20) rem(16);
    }

    .aside__btn-prev {
      margin: 0;
    }
  }

  &__btn-conteiner--history {
    margin: 50px 30px 30px 30px;

    @include max-media('dMax', 'mMax') {
      margin: 30px 20px 20px 20px;
    }
  }

  &__history-wrapper {
    position: relative;
  }

  &__history {
    background: #FFFFFF;
    border-radius: 15px;
    padding: 20px;
    width: 100%;
    max-width: 365px;
    margin: 0 auto;
    font-family: Calibri, serif;
    position: relative;

    @include max-media('dMax', 'mMax') {
      max-width: 308px;
    }
  }

  &__history-heading {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  &__history-image {
    margin-right: 10px;
    width: 51px;
    height: 51px;
  }

  &__history-name {
    font-size: 19px;
    font-weight: 700;
    line-height: 23.19px;
    color: #006746;
  }

  &__history-item {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__item-title {
    color: #006746;
    font-size: 19px;
    font-weight: 700;
    line-height: 23.19px;
  }

  &__item-description {
    color: #474747;
    font-size: 19px;
    font-weight: 400;
    line-height: 23.19px;
  }
}
