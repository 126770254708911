@import "../../styles/base";

.preloader {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(95, 146, 143, 0.70);
  backdrop-filter: blur(4.5px);

  &__wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__dots {
    filter: url(#gooeyness);
    width: fit-content;
    margin: 0 auto rem(56) auto;
  }

  &__dot {
    background: white;
    border-radius: 50%;
    display: inline-block;
    margin-right: 20px;
    width: 32px;
    height: 32px;

    &:first-child {
      animation: FirstDot 3s infinite;
    }

    &:nth-child(2) {
      animation: SecondDot 3s infinite;
    }

    &:nth-child(3) {
      animation: ThirdDot 3s infinite;
    }
  }

  &__tag-content {
    overflow: hidden;
    width: 375px;
    height: 60px;
    display: flex;
    position: relative;
  }

  &__tag {
    cursor: default;
    display: inline-block;
    padding: 10px 15px 10px 10px;
    border-radius: 60px;
    color: #FFFFFF;
    background: rgba(0, 0, 0, 0.20);
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 375px;
    flex-shrink: 0;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    animation: FirstTag 15s infinite;
  }

  @keyframes FirstTag {
    0% {
      opacity: 0;
      transform: translateX(-100%);
    }
    5% {
      opacity: 0;
      transform: translateX(-100%);
    }
    10% {
      transform: translateX(0);
      opacity: 1;
    }
    20% {
      transform: translateX(0);
      opacity: 1;
    }
    25% {
      transform: translateX(0);
      opacity: 1;
    }
    30% {
      transform: translateX(0);
      opacity: 1;
    }
    35% {
      transform: translateX(100%);
      opacity: 0;
    }
    40% {
      transform: translateX(100%);
      opacity: 0;
    }
    45% {
      transform: translateX(0);
      opacity: 1;
    }
    60% {
      transform: translateX(0);
      opacity: 1;
    }
    65% {
      transform: translateX(-100%);
      opacity: 0;
    }
    70% {
      transform: translateX(-100%);
      opacity: 0;
    }
    75% {
      transform: translateX(0);
      opacity: 1;
    }
    90% {
      transform: translateX(0);
      opacity: 1;
    }
    95% {
      transform: translateX(100%);
      opacity: 0;
    }
    100% {
      transform: translateX(100%);
      opacity: 0;
    }
  }

  @keyframes FirstDot {
    0% {
      transform: scale(1) translateX(0);
    }
    25% {
      transform: scale(1.5) translateX(0);
    }
    50% {
      transform: scale(1) translateX(0);
    }
    83% {
      transform: scale(1) translateX(150px);
    }
    100% {
      transform: scale(1) translateX(0);
    }
  }

  @keyframes SecondDot {
    0% {
      transform: translateX(0);
    }
    27% {
      transform: translateX(-50px);
    }
    50% {
      transform: translateX(0px);
    }
    81% {
      transform: translateX(100px);
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes ThirdDot {
    0% {
      transform: translateX(0);
    }
    29% {
      transform: translateX(-100px);
    }
    50% {
      transform: translateX(0px);
    }
    79% {
      transform: scale(1.5) translateX(30px);
    }
    100% {
      transform: translateX(0);
    }
  }
}
