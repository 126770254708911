html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Calibri', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

button,
input,
textarea,
select {
    background: none;
    font-family: 'Calibri', sans-serif;
    border: 0;
    border-radius: 0;
    outline: 0;
    transition: border 0.3s ease,color 0.3s ease;
    cursor: pointer;
    padding: 0;
}

h1, h2, p {
  margin: 0;
}

* {
  box-sizing: border-box;
}

.ps {
  &__rail-x {
    display: none !important;
  }

  &__rail-y {
    background-color: rgba($white, 0.4) !important;
    width: 2px;
    right: rem(12);
    left: auto !important;
    opacity: 1 !important;

    &:hover > .ps__thumb-y,
    &:focus > .ps__thumb-y,
    &.ps--clicking .ps__thumb-y {
      width: 7px;
    }
  }

  &__thumb-y {
    width: 7px;
    background-image: linear-gradient(to bottom, #FFE28D, #FCC937);
    right: -2.5px;
  }
}

.id-test {
  position: absolute;
  bottom: rem(5);
  left: rem(49);
  font: 500 16px / 20px 'Calibri', sans-serif;
  color: $black;
  z-index: 5;
  pointer-events: none;

  @media (max-width: 1023px) {
    bottom: 0;
    left: 15px;
    font-size: 10px;
  }

  @media (max-height: 1079px) {
    bottom: 0;
    left: 15px;
    font-size: 10px;
  }
}
