@import "../../styles/base";

.input {
    width: 100%;
    height: rem(72);
    background-color: $white;
    border-radius: rem(14);
    padding: rem(24) rem(20) rem(24) rem(15);

    @include max-media(dMax, mMax) {
        height: rem(40);
        padding: rem(7) rem(20) rem(7) rem(15);
        border-radius: rem(7);
    }

    input {
        width: 100%;
        height: 100%;
        font: 500 rem(19)/rem(23) "Calibri", sans-serif;
        outline: none;
        appearance: none;

        &::placeholder {
            color: $gum-leaf;
        }

        @include max-media(dMax, mMax) {
            font-size: rem(17);
            line-height: rem(25);
        }
    }

    &[data-error="true"] {
        border: 1px solid red;
    }
}
