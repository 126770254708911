@import "../base";

.test {
  overflow: hidden;
  position: relative;
  z-index: 1;
  background-color: $gulf-stream;
  //height: calc(100vh - #{$header_height});
  height: 100vh;

  @include max-media(tMax, sMax) {
    height: calc(100vh);
    height: calc(var(--vh, 1vh) * 100);
  }

  &--girl-black {
    #canvas {
      background-image: url('/assets/images/girl-black.jpg');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
  }

  &--woman-grey {
    #canvas {
      background-image: url('/assets/images/woman-grey.jpg');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
  }

  &--man-bald {
    #canvas {
      background-image: url('/assets/images/man-bald.jpg');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
  }

  &--short-test {
    background-color: rgba(95, 146, 143, 0.7);
  }

  &--first-test {
    background-image: url('/assets/images/gastro-intro.png');
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__survey {
    overflow: auto;

    @include min-media('d') {
      overflow: auto;
    }
  }

  &__survey-heading {
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #FFFFFF;
    margin: 0 auto rem(22) auto;

    @include min-media('d') {
      font-size: 50px;
      margin: 0 auto rem(148) auto;
    }
  }

  &__survey-cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: rem(30);
    gap: rem(17);

    @include min-media('d') {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-rows: auto auto;
    }
  }

  &__survey-card {
    padding: rem(22) rem(20) rem(12) rem(15);
    background-color: #FFFFFF;
    border-radius: rem(14);
    height: rem(237);
    cursor: pointer;
    display: flex;
    flex-direction: column;

    @include min-media('d') {
      display: block;
    }
  }

  &__card-value {
    width: rem(30);
    height: rem(30);
    border-radius: 50%;
    background-color: #9FC3B9;
    margin-left: auto;
    transition: background-color 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;

    &--viewed {
      background-color: #006746;

      .test__card-icon {
        opacity: 1;
      }

      //&:before {
      //  content: '';
      //  width: rem(19);
      //  height: rem(13);
      //  background-image: url('/assets/images/viewed-white.svg');
      //  background-repeat: no-repeat;
      //  background-size: cover;
      //}
    }
  }

  &__card-icon {
    height: 100%;
    opacity: 0;
    transition: opacity 0.3s;
  }

  &__card-text {
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: #006746;
    margin-top: rem(71);

    @include min-media('d') {
      font-size: 19px;
    }
  }

  &__btn-next {
    margin: 0 auto;
    opacity: 1;
    transition: opacity 0.3s;
    margin-bottom: rem(16);

    @include min-media('d') {
      margin: 0 auto;
    }

    &--hidden {
      opacity: 0;
    }

    &--swiper {
      position: absolute;
      bottom: rem(16);
      left: 50%;
      transform: translateX(-50%);
      z-index: 20;
      width: calc(100% - #{rem(80)});

      @include min-media('d') {
        bottom: rem(59);
        width: rem(365);
      }
    }
  }

  &__reaction {
    display: flex;
    min-height: 60px;
    align-items: center;

    position: fixed;
    top: 50%;
    right: calc(18% + #{$aside_width});
    width: rem(353);
    color: $watercourse;
    background-color: $white;
    border-radius: rem(14);
    padding: rem(20);
    font: 500 rem(26) / rem(36) 'Calibri', sans-serif;
    z-index: 5;

    @include max-media(xlMax, sMax) {
      font-size: rem(19);
      line-height: rem(23);
      padding: rem(10);
      width: rem(236);
    }

    @include max-media(tMax, sMax) {
      font-size: rem(14);
      line-height: rem(18);
      width: rem(200);
    }

    &[data-point="0"] {
      &::after {
        content: '';
        position: absolute;
        left: -38px;
        bottom: 12px;
        border: 20px solid transparent;
        border-right: 20px solid $white;

        @include max-media(dMax, tMax) {
          border-width: 10px;
          left: -18px;
        }
      }

      @media (min-width: 1441px) {
        right: calc(#{rem(20)} + #{$aside_width});
      }

      @media (max-width: 1440px) {
        right: calc(#{rem(18)} + #{$aside_width});
      }

      @include max-media(dMax, sMax) {
        right: calc(#{rem(22)} + #{rem(295)});
      }
    }

    &[data-point="1"] {
      top: 20%;
      right: calc(28% + #{$aside_width});

      .test-man & {
        @media (min-width: 1280px) {
          top: 16%;
          right: calc(#{rem(20)} + #{$aside_width});
        }
      }

      @media (max-width: 1440px) {
        right: calc(10% + #{$aside_width});
      }

      @include max-media(dMax, sMax) {
        top: calc(#{rem(120)} + #{$header_height});
        right: calc(#{rem(70)} + #{rem(295)});
      }

      &::after {
        content: '';
        position: absolute;
        left: 20px;
        bottom: -38px;
        border: 20px solid transparent;
        border-top: 20px solid $white;

        @include max-media(dMax, tMax) {
          border-width: 10px;
          bottom: -18px;
        }
      }
    }

    &[data-point="2"] {
      // top: 30%;
      // left: rem(146);
      top: 20%;
      left: rem(48);

      .test-man & {
        @media (min-width: 1280px) {
          top: 18%;
          left: 14%;
        }
      }

      @media (max-width: 1440px) {
        left: rem(24);
      }

      @include max-media(dMax, sMax) {
        // top: calc(#{rem(120)} + #{$header_height});
        top: calc(#{rem(100)} + #{$header_height});
        left: rem(10);
      }

      &::after {
        content: '';
        position: absolute;
        right: 40px;
        bottom: -38px;
        border: 20px solid transparent;
        border-top: 20px solid $white;

        @include max-media(dMax, tMax) {
          border-width: 10px;
          bottom: -18px;
        }
      }
    }

    &[data-point="3"] {
      top: 40%;
      left: rem(48);

      .test-man & {
        @media (min-width: 1280px) {
          left: 14%;
        }
      }

      @media (max-width: 1440px) {
        left: rem(24);
      }

      @include max-media(dMax, sMax) {
        top: 50%;
        left: rem(10);
      }

      &::after {
        content: '';
        position: absolute;
        right: -38px;
        bottom: 20px;
        border: 20px solid transparent;
        border-left: 20px solid $white;

        @include max-media(dMax, tMax) {
          border-width: 10px;
          right: -18px;
        }
      }
    }

    &[data-point="4"] {
      top: 40%;
      left: rem(48);

      .test-man & {
        @media (min-width: 1280px) {
          left: 14%;
        }
      }

      @media (max-width: 1440px) {
        left: rem(24);
      }

      @include max-media(dMax, sMax) {
        top: 50%;
        left: rem(10);
      }

      &::after {
        content: '';
        position: absolute;
        right: -38px;
        bottom: 20px;
        border: 20px solid transparent;
        border-left: 20px solid $white;

        @include max-media(dMax, tMax) {
          border-width: 10px;
          right: -18px;
        }
      }
    }

    &[data-point="0"],
    &[data-point="1"],
    &[data-point="2"],
    &[data-point="3"],
    &[data-point="4"] {
      @include max-media(tMax, sMax) {
        top: auto;
        bottom: 20%;
        left: rem(20);
        right: auto;
      }

      &::after {
        @include max-media(tMax, sMax) {
          display: none;
        }
      }
    }

    &--new {
      &[data-point="0"],
      &[data-point="1"],
      &[data-point="2"],
      &[data-point="3"],
      &[data-point="4"] {
        height: fit-content;
        top: 50%;
        transform: translateY(-50%);
        left: 10%;

        &:after {
          left: auto;
          right: -32px;
          bottom: 50%;
          transform: translateY(50%);
          border: 18px solid transparent;
          border-left: 16px solid #ffffff;
          @include min-media('xl') {
            right: -40px;
            border: 22px solid transparent;
            border-left: 20px solid #ffffff;
          }
        }
      }
    }

    &--visible {
      animation-name: visible;
      animation-duration: 2.3s;
    }
  }

  @keyframes visible {
    0% { opacity: 0; }
    50% { opacity: 0; }
    80% { opacity: 0; }
    100% { opacity: 1; }
  }

  &__video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: left;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    visibility: hidden;
    opacity: 0;
  }

  &__survey {
    display: flex;
    height: 100%;

    &--newTest {
      flex-direction: column;
      width: 100%;
      padding: rem(58) rem(48) 0 rem(48);
    }
  }

  &__survey-list {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    overflow: hidden;
    padding: rem(70) rem(50) 0 rem(25);
    width: calc(100% - #{$aside_width});
    position: relative;
    flex-wrap: wrap;
    height: 100%;
    overflow: auto;
    margin: 0 -15px;

    @include max-media(dMax, dMax) {
      width: calc(100% - #{rem(295)});
      padding: 0 rem(31);
      flex-wrap: wrap;
    }

    @include max-media(tMax, sMax) {
      padding: rem(79) 0 0 rem(39);
    }

    @media screen and (max-width: 735px) and (orientation: landscape) {
      width: 55%;
      padding: 0 0 0 rem(16);
    }

    .ps__rail-y {
      right: rem(4) !important;
    }

    .ps__thumb-y {
      background-color: #33728D;
      background-image: none;
      border-radius: rem(10);
    }
  }

  &__survey-item {
    width: calc(100% / 3);
    height: rem(311);
    background-color: $white;
    border-radius: rem(20);
    padding: rem(20) rem(26) rem(20) rem(18);
    font: 700 rem(25)/rem(25) 'Calibri', sans-serif;
    color: rgba($black, 0.7);
    position: relative;
    overflow: hidden;
    cursor: pointer;
    max-width: rem(288);
    margin: 15px;

    @include max-media(dMax, mMax) {
      width: calc(50% - 15px);
    }

    @include max-media(tMax, sMax) {
      width: calc(50% - 10px);
      margin: rem(11) rem(5);
      font-size: rem(13);
      line-height: rem(13);
      height: rem(236);
      padding: rem(20) rem(16) rem(20) rem(13);
    }

    @media screen and (max-width: 735px) and (orientation: landscape) {
      height: rem(150);
    }

    &.active {
      box-shadow: 0 1px 20px #3A5957;
    }

    img {
      display: block;
      position: absolute;
      max-width: calc(100% - #{rem(40)});
      bottom: 0;
    }
  }

  &__survey-img {
    width: calc(100% - #{rem(195)});
    display: flex;
    align-items: flex-start;
    justify-content: center;
    min-height: 100%;
    margin: 0 auto;

    @include max-media(tMax, sMax) {
      width: 100%;
    }

    img {
      display: block;
      width: 100%;
    }
  }

  &__aside {
    .aside__header {
      @include max-media(dMax, mMax) {
        display: none;
      }
    }
  }
}

#canvas {
  position: absolute;
  //top: -#{$header_height};
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% + #{$header_height});
  z-index: -1;

  @include max-media(tMax, sMax) {
    top: -#{rem(72)};
    height: calc(100% + #{rem(72)});
  }

  @media (max-width: 810px) {
    top: 0;
    height: 100%;
  }

  .test-man & canvas {
    float: right;
  }
}
